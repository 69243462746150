

































































































































































import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';
import {
  Web
} from '@/store/web'

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'
import {
    Iit
} from '@/store/iit'
import _ from "lodash"

@Component({
    components: {
         
    },
})
export default class Home extends Vue {

    private user!: any;
    private yearData!: any;
    private agency: any = null

    private assessmentTab: number = 0
    private assessmentData!: any;

    private issueRaw: any = [];
    private issueData: any = null;
    private response: boolean = false;

    private userDone: number = 0
    private scoreAll : any = []
    private score100 :number = 0
    private score30 :number = 0

    public async created() {
      await Web.switchLoad(true)
        await this.run();
        await this.getAssessment();
        await this.getUserAnswer()
        await this.generateScore();
        await this.getAverage();
      //  await this.start()

      await Web.switchLoad(false)

        this.response = true
    }

    private async run() {
        this.user = await User.getUser();
        this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.$route.query.agency}/`)
        this.yearData = await Core.getHttp(`/api/iit/v2/year/${this.$route.query.year}/`)
        
    }

    private async getAssessment() {
        this.assessmentData = await Core.getHttp(`/api/iit/v2/assessmentissues/?&year=${this.yearData.id}`)
        this.assessmentTab = 0
        await this.getRawIssue(this.assessmentData[0].id)
    }

    private async getUserAnswer() {
        let user = await Core.groupBy(this.issueRaw, 'user')
        this.userDone = user.length
    }

    private async start(){
      let assessmentData = this.assessmentData[0]
      //console.log(newIndex, assessmentData.id)
      await this.getRawIssue(assessmentData.id)
      await this.generateScore()
    }



    @Watch('assessmentTab')
    private async switchTab(newIndex: number, oldIndex: number) {
        let assessmentData = this.assessmentData[newIndex]
        //console.log(newIndex, assessmentData.id)
        await this.getRawIssue(assessmentData.id)
      await this.generateScore()
    }

    private async getRawIssue(assignId: number) {
        this.issueRaw = await Core.getHttp(`/api/iit/v2/answerissue-report/?agency=${this.$route.query.agency}&assessmentIssues=${assignId}`)
    }

    private async generateScore() {
        if(this.issueRaw.length > 0){
        let issueGroup = await Iit.groupIssueRaw(this.issueRaw, this.userDone, this.agency.count);
        this.issueData = issueGroup
        }
      
    }
    toFixed2(num:number){
        return num.toFixed(2);
    }
    sumScore(arr:any){
        //console.log(arr);
        let numberArr = arr.length;
        let sumAvg =  _.sumBy(arr, 'avg');  
        let score = (sumAvg / arr.length); 
        return score.toFixed(2);
    }




    async getAverage(){
        let count = 0;
        let sumOutAvg = 0;
        let choice = 0;
        for (let i=0; i < this.assessmentData.length ; i++){
            let raw = await Core.getHttp(`/api/iit/v2/answerissue-report/?agency=${this.$route.query.agency}&assessmentIssues=${this.assessmentData[i].id}`)
            let issueGroup = await Iit.groupIssueRaw(raw, this.userDone, this.agency.count);
            for(let j=0; j < issueGroup.length; j++){
               let sumAvg =  this.sumScore(issueGroup[j].value)  
               sumOutAvg += Number(sumAvg)
               choice++;
              
            }
           
        }
        this.score100 = Number((sumOutAvg/choice).toFixed(2))
        this.score30 =  Number(((sumOutAvg/choice)*0.3).toFixed(2))
        if(isNaN(this.score30)  ){
            this.score30 = 0.00
        }
     
    }

}
